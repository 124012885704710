import { ReactNode } from 'react';
// Components
import Modal from 'ui/Modal/Modal';
// Icons
import Icon from 'ui/Icon/Icon';

import styles from './FiltersToggle.module.scss';

type FiltersToggleProps = {
  filtersModalIsVisible: boolean;
  toggleFiltersVisibility: () => void;
  children?: ReactNode;
};

const FiltersToggle = ({
  filtersModalIsVisible,
  toggleFiltersVisibility,
  children,
}: FiltersToggleProps) => {
  return (
    <>
      <button
        className={styles.filterToggleButton}
        onClick={toggleFiltersVisibility}
        type="button"
      >
        <span className={styles.filterToggleButtonTitle}>Filter</span>
        <Icon name="round-sorting" className={styles.filterToggleButtonIcon} />
      </button>

      <Modal
        size="medium"
        className={styles.filtersModal}
        open={filtersModalIsVisible}
        onClose={toggleFiltersVisibility}
        focusTrapped={false}
      >
        {children}
      </Modal>
    </>
  );
};

export default FiltersToggle;
